@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;500;700&family=Chivo:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
}

html{
  background-color: #f8f8f8;
  color: #f8f8f8;
}

nav{
  z-index: 100;
  border-radius: 50px;
  outline: #ffffff9b solid 0.3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px,rgba(0, 0, 0, 0.06) 0px 0px 0px 0.5px, inset 0 0 100px #ffffff;
  -webkit-backdrop-filter: blur(5px) grayscale(50%) brightness(99%);
  backdrop-filter: blur(5px) grayscale(50%) brightness(99%);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-top: 2vh;
  top: 0;
  left: 0;      
  right: 0;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  max-width: 220px;
}

nav.normal-dock {
  width: 100px;
  height: 60px;
}

nav.project-dock {
  width: 100px;
  height: 60px;
}

.nav-links li.center-item {
  margin: 0 auto;
}

#dark nav {
  backdrop-filter: brightness(300%) blur(15px) grayscale(50%);
}

.nav-links {
  display: flex;
  width: 100%; 
  justify-content: space-between; 
  list-style: none;
}

.nav-links li {
  text-align: center; 
  height: auto;
  text-decoration: none;
  color: #bdbdbd; 
  transition: color 0.5s ease; 
  padding-left: 2rem;
  padding-right: 2rem;
}

.nav-links li.center-link {
  padding-left: 0;
  padding-right: 0;
}

.nav-links li.active-link.center-link::after {
  display: none;
}

.nav-links li:hover {
  color: #19a0fc; 
  transition: color 0.5s ease; 
}

.nav-links .active-link li {
  color: #000000; 
}

.active-links li::after {
  color: #000000; 
}

.active-link::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px; 
  transform: translateX(-50%);
  width: 6px; 
  height: 6px; 
  background-color: #ffffffcf; 
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px inset, rgba(0, 0, 0, 0.3) 0px 1.8px 3.6px -1.8px inset;
}

