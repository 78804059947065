@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;500;700&family=Chivo:wght@700&display=swap");

* {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html{
  background-color: #f8f8f8;
  color: #f8f8f8;
  font-family:  "Barlow", "Inter", Arial, Helvetica, sans-serif;
}

body {
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  display: none;
}

#wrapper {
	min-width: 100vw;
	background-color:#f8f8f8;
  margin-top: 0;
}

.container {
  display: flex;
  justify-content: space-around;
  margin: 0;
  margin-top: 8rem;
}

/*Responsible for width*/
.text-block {
  width: 600px;
}

.line {
  margin-bottom: 2rem;
  max-width: auto;
  height: 1px;
  background: #f1f1f1;
}

.line-projects {
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: auto;
  height: 1px;
  background: #f1f1f1;
}

h1,
p {
  text-align: left;
  white-space: pre-line;
}

h1, 
h2,
h3,
h4 {
  font-size: 15pt;
  font-weight: 400;
  color: #272727;
}

h3 
{
  font-size: 12pt;
  font-weight: 400;
  color: #272727;
  padding-bottom: 0.5rem;
}


h2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

a,
p
{
  font-size: 12pt ;
  letter-spacing: 0.003em;
  word-spacing: 0.05rem;
}

a {
  text-decoration: none;
  font-weight: 400;
  color: #272727;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);  
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
}

p {
  padding-bottom: 2rem;
  line-height: 2;
  color: #898989;
}

li {
  list-style: none;
}

.video{
  width: 100%;
  height: 100%;
}

.video-container{
  height:330px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

::selection {
  background-color: #f1ffba;
}

.link {
  text-decoration: none;
  color: #19a0fc;
  font-size: 12pt ;
  font-weight: 500;
}

.socials {
  line-height: 1.62;
}

.add-info {
  color: #bdbdbd;
  letter-spacing: 0.008em;
}

.day-date {
  padding-bottom: 0.6rem;
  color: #bdbdbd;
  letter-spacing: 0.008em;
}

.item-work :hover {
  cursor: pointer;
  margin-left: -1rem;
  padding-left: 1rem;
  margin-right: -1rem;
  padding-right: 1rem;
  background-color: #efefef;
  transition: background-color ease-out 0.2s;
  border-radius: 0.5rem;
}

.banner{
  margin-bottom: 1.25rem;
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.image-wide{
  margin-bottom: 1.25rem;
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.spacer {
  font-weight: 300;
  margin-bottom: -1rem;
  font-size: 1.15rem;
  color: #bdbdbd;
}

/*CITATION*/

.citation {
  font-size: 14px;
  color: #bdbdbd;
  padding-bottom: 0.1rem;
}

.citation-container{
  margin-bottom: 2rem;
}

/*SIGNATURE*/
.signature{
  margin-top: -1.3rem;
  margin-bottom: 2rem;
}

path {
  stroke-dasharray: 800px;
  stroke-dashoffset: 800px;
  animation: signatureAnimation 4s ease-in-out forwards;
}

.bottom-work{
  margin-bottom: 5rem;
}

@keyframes signatureAnimation {
  to{
  stroke-dashoffset: 0px;
  }
}

/*STAR*/

.star {
  position: absolute;
  left: -1.4rem;
  top:1rem;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: animateSVG 3s ease-in-out forwards;
  pointer-events: none; 
}

@keyframes animateSVG {
  to {
    stroke-dashoffset: 0;
  }
}


/*GRID*/
.grid {
  max-width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.col {
  flex-direction: column;
  width: 100%;
  height: 300px;
  margin-bottom: 1rem;
}

.double-col {
  flex-direction: column;
  width: 100%;
  height: 600px;
  margin-bottom: 1rem;
}

.centered-col {
  height: 300px;
  width: 300px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 1rem;
}

.grid-process {
  max-width: 100%;
  perspective: 1000px;
}

.row-process {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.75rem;
}

.col-process {
  flex-direction: column;
  width: 100%;
  height: 135px;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
}

.col-process:hover {
  transform: scale(1.5);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

video
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/*MEDIA CONTAINER GRID*/

.media-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.media-container .media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.5s ease;
}

.media-container .description-overlay {
  position: absolute;
  bottom: 0;
  color: #bdbdbd;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(100%);
  opacity: 0;
}

.media-container:hover .media {
  filter: blur(5px) grayscale(100%);
}

.media-container:hover .description-overlay {
  transform: translateY(10%);
  opacity: 1;
}

.description-textbox {
  background: #ffffffb8;
  color: #898989;
  margin: 3px;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}

/*PROJECTS*/

.tools {
  display: flex; 
  list-style: none; 
  padding: 0; 
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tool {
  padding: 2px 5px;
  margin-right: 10px; 
  margin-bottom: 10px;
  border-radius: 5px;
  background: #ffffff;
  color: #898989;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

/*WORK*/

.list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
}

.item {
  list-style: none;
}

#year {
  color: #898989;
}

.mouse-follower {
  position: absolute;
  pointer-events:none;
  width: 330px;
  height: 330px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px,rgba(0, 0, 0, 0.06) 0px 0px 0px 0.5px, inset 0 0 100px #ffffff;
  background-color: #fff;
}

.mouse{
  position: relative;
  width: 100%;
  height: 330px;
  z-index: 1;
  overflow: hidden;
}


@media (max-width: 480px) {

  .text-block {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .item-work:hover,
  .media-container:hover .media,
  .media-container:hover .description-overlay {
    pointer-events: none;
  }

  .text-block {
    margin-top: -2rem;
  }

  .row {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .col {
    width: 100%; 
  }

  .centered-col{
    width: 100%;
  }

  .media-container:hover .media,
  .media-container:hover .description-overlay {
    pointer-events: none;
    filter: none !important;
    transform: none !important;
    opacity: 1 !important; 
  }
  .description-overlay{
    display: none;
  }

  .mouse-follower {
    display: none;
  }
  
  .mouse{
    display: none;
  }

 }


@media (max-width: 600px) {
  .text-block {
    margin-left:2rem;
    margin-right:2rem;
  }

 }


@media (min-width: 768px) and (max-width: 979px) { }

@media (min-height: 900px) { 
  
}

@media (min-height: 1400px) { 

  
}

.canvas-container {
  box-sizing: border-box;
  width: 100%; 
  height: 500px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: grab;
}

.canvas-container-sensorium {
  box-sizing: border-box;
  width: 100%; 
  height: 600px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: grab;
}

.process
{
  margin-bottom: 1rem;
}
.process li
{ 
  font-size: 12pt ;
  list-style:none;
  color: #272727;
  letter-spacing: 0.048px;
  line-height: 25px;
}

.numbered-list
{
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.numbered-list li
{
  font-size: 12pt ;
  list-style:decimal;
  color: #898989;
  letter-spacing: 0.048px;
  line-height: 32px;
}

.dotted-list
{
  padding-left: 1.2rem;
  margin-bottom: 1rem;
}

.dotted-list li
{
  font-size: 12pt ;
  list-style: disc;
  color: #898989;
  letter-spacing: 0.048px;
  line-height: 32px;
}

.check-list
{
  padding-left: 1.1rem;
  margin-bottom: 1rem;
}

.check-list li 
{
  font-size: 12pt ;
  list-style:circle;
  color: #898989;
  letter-spacing: 0.048px;
  line-height: 32px;
}

.sample-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.sample-box {
  max-width: 300px;
  max-height: 500px;
  background-color: #35363a;; 
  padding: 1rem; 
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.carousel{
  padding: 0;
}

.block-quote {
  margin-bottom: 3.5rem;
  padding: 0.5em 1em;
  background-color: #f9f9f9;
  border-left: 3px solid #ccc;
}

.infographic{
  box-shadow: none;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
}

.description-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  color: #898989;
  padding: 2px 10px;
  border-radius: 10px;
  box-shadow: rgba(27, 31, 35, 0.1) 0px 4px 6px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  z-index: 100;
  display: inline-block;
  visibility: visible;
  text-align: center;
}

.description-box p {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.lazy-load-image-wrapper,
.lazy-load-video-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}


.lazy-image,
.lazy-video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.lazy-image.visible,
.lazy-video.visible {
  opacity: 1;
}

.lazy-image-placeholder,
.lazy-video-placeholder {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
  filter: blur(10px); 
  opacity: 1;
}

.lazy-image-placeholder.fade-out,
.lazy-video-placeholder.fade-out {
  opacity: 0;
  filter: blur(0);
  transition: opacity 1s ease-in-out, filter 1s ease-in-out;
}

.lazy-load-image-wrapper img,
.lazy-load-video-wrapper video {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.butterfly-canvas{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: visible;
}

.social-buttons {
  display: flex;
  gap: 5rem;
  width: 100%; 
  margin-top: 3rem;
}

.button {
  color: #898989;
  flex: 1;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 100px;
  transition: background-color 0.3s ease;
  outline: #ffffff9b solid 0.3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.button:hover {
  background-color: #0056b3;
}
